<template>
    <div class="SystemDetails">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="系统消息"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="details-bg">
            <div class="details">
                <p class="details-left">{{type.msg_title}}</p>
                <p class="details-right">{{type.time_before}} {{type.time_detail}}</p>
            </div>
            <p class="details-text" v-html="type.content">

            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SystemDetails",
        data() {
            return {
                type: {},
                readingList: [],
            }
        },
        created() {
            this.type = this.$route.query.type
            this.reading()
        },
        methods: {
            reading() {
                this.$axios.post('/api/message/reading', {
                    id: this.type.id,
                    type: this.type.type,
                })
                    .then(res => {
                    })
            }
        },
    }
</script>

<style scoped>
    .details-text {
        color: #606266;
        font-size: 28px;
        text-align: justify;
        margin-top: 30px;
        line-height: 50px;
    }

    .details-bg {
        background: #f3f5f7;
        border-radius: 10px;
        padding: 40px;
        margin: 40px;
    }

    .details-left {
        color: #0A1C33;
        font-size: 30px;
    }

    .details-right {
        font-size: 20px;
        color: #BFC2CC;
    }

    .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>